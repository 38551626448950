.fullResult {
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 1103;
  width: 100vw;
  height: 100vh;
}

.Result-Cap {
  width: 90vw;
  height: fit-content;
  margin: 0%;
  padding: 0%;
  padding-left: 5vw;
  border: 0%;
  position: fixed;
  z-index: 1101;
  left: 0;
  top: 7vw;
  background-color: white;
}

.resultTitle {
  font-size: 7.5vw;
  font-weight: 800;
  width: 60vw;
  position: relative;
}

.resultSub {
  font-size: 4vw;
  margin-top: 1vw;
  font-weight: 500;
  width: 40vw;
  position: relative;
}

.blueCircleResult {
  position: absolute;
  top: -1vw;
  right: 0vw;
  width: 18vw;
  height: 18vw;
  border-radius: 18vw;
  border: 2vw solid #ff0000;
  font-weight: 600;
  font-size: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.swiperHeadFlex {
  font-size: 5.2vw;
  width: 100%;
  display: flex;
  font-weight: 600;
  justify-content: space-around;
  position: relative;
  top: 10vw;
}

.yr {
  border-bottom: 1vw solid #0072e2;
  padding-bottom: 1.5vw;
}

.swipeOverall {
  position: relative;
  top: 16vw;
  overflow: hidden;
  padding-bottom: 5vw;
}

.questionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  position: relative;
  margin-bottom: 1vw;
}

.questionBoxCircle {
  display: flex;
  width: 99%;
  height: 15vw;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5vw;
}

.questionCircle {
  width: 10vw;
  height: 10vw;
  border-radius: 10vw;
  border: 1vw solid #17ac00;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  font-weight: 700;
  box-shadow: -0.05em 0.1em 0.2em 0.1vw #a8a8a8;
}

.questionBox {
  height: 8vw;
  width: 69vw;
  border: 0.8vw solid #17ac00;
  border-radius: 4vw;
  box-shadow: -0.05em 0.1em 0.2em 0.1vw #aaaaaa;
  font-size: 2.8vw;
  padding: 2vw;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  align-items: center;
}

.teammatesWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 1vw;
  padding-left: 1vw;
  justify-content: center;
  align-items: center;
}

.teammateFlex {
  display: flex;
  width: 100%;
  height: 15vw;
  align-items: center;
  padding-left: 0.5vw;
  position: relative;
  left: 1vw;
  overflow: hidden;
}

.teammateCircle {
  width: 9vw;
  height: 9vw;
  box-shadow: -0.05em 0.1em 0.3em 0vw #000000;
  border-radius: 9vw;
  overflow: hidden;
}

.teammatePic {
  width: 100%;
}

.teammateName {
  font-size: 4vw;
  font-weight: 700;
  position: relative;
  left: 5vw;
}

.teammatePCT {
  font-size: 4vw;
  font-weight: 700;
  position: absolute;
  right: 5vw;
}

#sTextQ {
  font-size: 3vw;
}
#mTextQ {
  font-size: 3.4vw;
}
#lTextQ {
  font-size: 4vw;
}

.returnToHomeCap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vw;
  display: flex;
  justify-content: center;
  background: linear-gradient(to top, rgb(106, 106, 106), rgba(0, 0, 0, 0));
  z-index: 1000000000000000000;
}
.backToHome {
  width: 45vw;
  height: 15vw;
  border-radius: 5vw;
  background-color: #0072e2;
  display: flex;
  position: relative;
  margin-top: 15vw;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4.2vw;
  font-weight: 500;
  box-shadow: 0em 0.05em 0.4em 0.05vw #2b2b2b;
}
