.Quiz {
    width: 85%;
    left: 7.5vw;
    height: fit-content;
    z-index: 10000000000000000000000;
    position: relative;
    top: 27vw;
}

.createCap{
    display: flex;
    background-color: white;
    height: 15vw;
    width: 88%;
    padding-left: 5vw;
    padding-right: 5vw;
    border-radius: 3vw;
    box-shadow: -.02em 0.11em 0.3em 0.07vw #323232;
    position: relative;
    margin-top: 7vw;
    align-items: center;
    justify-content: space-around;
}

.creatInnerflex{
    display: flex;
    align-items: center;

}
.createText {
    font-weight: 600;
    color: #0072E2;
    justify-content: space-between;
    width: 60vw;
    font-size: 3.5vw;

}


.currentQuiz {
    font-size: 7vw;
    letter-spacing: .15vw;
    color: white;
    font-weight: 700;
    padding-bottom: 7vw;
    position: relative;
    margin-top: 7vw;
}

.pastQuiz {
    font-size: 6.5vw;
    letter-spacing: .15vw;
    color: white;
    font-weight: 700;
    padding-bottom: 7vw;
    padding-top: 4vw;
}

