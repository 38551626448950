.Home {
    width: 85%;
    left: 7.5vw;
    height: fit-content;
    z-index: 0;
    position: fixed;
    top: 27vw;
}

.greeting{
    font-size: 8.5vw;
    color: white;
    width: 70vw;
    font-weight: 600;
}

.NPFlex {
    width: 100%;
    position: relative;
    top: 8vw;
    height: fit-content;
    display:flex;
    justify-content: space-between;
}

.NPFlexItem {
    width: 39.5vw;
    height: 13vw;
    border-radius: 3.5vw;
    box-shadow: .05em 0.1em .4em 0.1vw #5f5f5f;
    background-color: white;
    display:flex;
    align-items: center;
}
.NPInnerFlex {
    width: 100%;
    position: relative;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NPInnerFlexItem {
    width: fit-content;
    font-size: 3.5vw;
    padding-left: 3vw;
    margin-top: .3vw;
    font-weight: 600;
}

.notifications {
    width: 100%;
    height: 20vw;
    position: relative;
    top: 17vw;
}

.notHeader {
    font-size: 6vw;
    letter-spacing: .15vw;
    color: white;
    font-weight: 600;
    padding-bottom: 4vw;
}

.notCap {
    height: 40vh;
    width: 89vw;
    position: relative;
    left: -2vw;
    overflow: auto;
    padding-bottom: 5vw;
}