.QuizD {
    width: 85%;
    height: fit-content;
    z-index: 10000000000000000000000;
    position: relative;
}

.quizzesHead {
    font-size: 3vw;
    font-weight: 700;
    position: relative;
    top: 2vw;

}

.createCapD{
    display: flex;
    background-color: white;
    height: 5vw;
    width: 100%;
    border-radius: 1vw;
    box-shadow: -.02em 0.11em 0.3em 0.07vw #323232;
    position: relative;
    align-items: center;
    justify-content: space-around;
    margin-top: 4.5vw;
    border: 0.15vw solid #5259FB;
}

.creatInnerflexD{
    display: flex;
    align-items: center;
    width: 16vw;
}
.createTextD {
    font-weight: 600;
    font-size: 1vw;
    color: #5259FB;
    width: 80%;
    margin-left: 1vw;


}


.currentQuizD {
    font-size: 2vw;
    letter-spacing: .05vw;
    color: black;
    font-weight: 500;
    position: relative;
    margin-top: 2.5vw;
}

.pastQuizD {
    font-size: 2vw;
    letter-spacing: .05vw;
    color: black;
    font-weight: 500;
    margin-bottom: 2vw;
    margin-top: 1vw;
}

.cQuizOver {
    height: fit-content;
    max-height: 24vw;
    padding: 1vw;
    overflow: auto;

}
