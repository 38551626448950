
.back-container-create{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 4.8vw;
    align-items: center;
    left: 3vw;
    top: 2.5vw;
    font-size: 1.35vw;
    font-weight: 600;
  }

  .innercreate1 {
    width: 33%;
    height: 75%;
    border-right: 0.15vw solid rgba(195, 195, 195, 0.4);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
  
  
  }
  .innercreate2 {
    width: 33%;
    height: 75%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
  
  
  }