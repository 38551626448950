

  .hollowDotGroup {
    width: 3vh;
    height: 3vh;
    border: .15vh solid rgb(81, 81, 81) ;
    border-radius: 2vh;
  }

  .ProfDivGroupD{
    height: 4.5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 2vh;
    margin-left: 1vh;
  }
  
  .profPicGroupD {
    width: 4.5vh;
    height: 4.5vh;
    position: relative;
    box-shadow: 0em 0.0em 0.4em 0.15vh #c3c3c3;
    border-radius: 3vh;
    overflow: hidden;
  }
  
  .picGroupD {
    width: 100%;
  }

  .nameGroupOuterD {
    position: relative;
    display: flex;
    width: 80%;
    left: 2vh;
    justify-content: space-between;
  }

  .nameHeadGroupD {
    font-size: 2.2vh;
    font-weight: 800;
  }
  .nameSubGroupD {
    width: 100%;
    font-size: 1.3vh;
    font-weight: 500;
    position: relative;
    left: 0vw;
  }
  











  
  .div-item2D{
    height: 4vw;
    width: 70%;
    display: flex;
    align-items: center;
    margin-bottom: 1vw;
    margin-top: 1.5vw;
  }

  .name3D {
    position: relative;
    display: flex;
    width: 60%;
    left: 1.5vw;
    justify-content: space-between;
    align-items: center;
  }