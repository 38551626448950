

.fullCurrentQuiz {

    background-color: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: .7;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000000000000000;

}

.backContainer-CurrentQuiz{
  position: fixed;
  top: 3.5vh;
  left: 2vw;
  z-index: 1000000000000000000;
  display: flex;
  align-items: center;
  width: 9vh;
  justify-content: space-between;
  color: white;
}

.backTxtCurrentQuiz {
  font-size: 2.5vh;
  font-weight: 600;
  }

.topBarCurrentQuiz {
  position: fixed;
  width: 100%;
  height: 10vh;
  background-color: black;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 10000000000000000000;
}
.topBarTitleCurrentQuiz {
  color: white;
  font-weight: 600;
  font-size: 2.4vw;
  
}

.topBarQuestions {
  color: white;
  font-size: 3vh;
  font-weight: 400;

}

.flexColumnCurrentQuiz {
  width: 13vh;
  position: fixed;
  height: 11.5vw;
  top: 2.5vh;
  right: 0vw;
  display: flex;
  justify-content: space-between;
}

.boxDelete{
  width: 5.2vh;
  height:5.2vh;
  background-color: white;
  display: flex;
  position: relative;
  left: 2vw;
  justify-content: center;
  align-items: center;
  border-radius: 1vh;
}

.innerFlexTopBarC{
  display: flex;
  width: 40vw;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10000000000000000000;
  position: relative;
  bottom: 1.5vw;
  
  

}

.currQuizOver {
  width: 100vw;
  height: 90%;
  position: fixed;
  z-index: 10000000000000000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  top: 10%;
}

.questionOver {
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.currQuizQuestion {
  font-size: 1.7vw;
  padding: 1vw;
  font-weight: 600;
  color: white;
  height: 6%;
  padding-top: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.playPicCapDQuiz {
  width: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 62%;
}

.playPicDQuiz {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

.answersCD{
  width: 100%;
  height: 8%;
  position: relative;
  margin-top: 1.5vw;
  display: flex;
  justify-content: space-between;
  }

.answerCD{
  width: 44%;
  height: 100%;
  border: .2vw solid black;
  border-radius: 1vw;
  position: relative;
  box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  text-align: center;
  font-size: 1vw;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  overflow: hidden;
  padding-right: 1vw;
  padding-left: 1vw;
}

.unAnswer2CD{
width: 44%;
height: 100%;
border: .2vw solid rgb(12, 215, 12);
border-radius: 1vw;
position: relative;
box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
text-align: center;
font-size: 1vw;
font-weight: 500;
display: flex;
flex-direction: column;
justify-content: center;
background-color: white;
overflow: hidden;
padding-right: 1vw;
padding-left: 1vw;
}

.innerACD {
  overflow: hidden;

  width: 100%;
  font-size: 1vw;    
  font-weight: 500;
  max-width: 100%;

}

.arrowBox {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vw;
  border-radius: 1.5vw;
}




.blackBackDelQuiz {
  position: fixed;
  z-index: 10000000000000000000000000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delQuizBox {
  width: 30vw;
  height: 11vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  background-color: white;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  padding-bottom: 3vw;

  padding-left: 3vw;
  padding-right: 3vw;
  justify-content: space-between;
  text-align: center;
  font-size: 1.1vw;
}
.delQuizBoxHead {
  font-size: 2.2vw;
  font-weight: 600;
}
.DPdelQuiz {
  width: fit-content;
  height: 3.5vw;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  border-radius: 1.5vw;
  font-weight: 600;
  position: relative;
  top: 1vw;

  padding-left: 2vw;
  padding-right: 2vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
}
































  
  




