:root {
  --color-primary: white;
  --color-secondary: ;
  --color-tertiary: ;
  --color-quaternary: ;
}



/*  Background  */



.SID {
  text-align: left;
  margin-left: 0%;
  width: 100vw;
  padding: 0%;
  border: 0%;
  height: 100vh;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  background-image: linear-gradient(to bottom, #006bd6 30%, #2c78c4);  
  display: flex;
  justify-content: center;
  align-items: center;
}


.overCap {
  position: relative;
  top: -2vw;
}

.GPCapD{
  width: 100vw;
  position: relative;
  top: 2vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;

}

.headCapD{
  width: 100vw;
  position: relative;
  top: 0vh;
  height: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;

}

.subCapD{
  width: 100vw;
  position: relative;
  color: white;
  height: 2vw;
  top: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  padding-top: 4.5%;
}
.logo2 {
  position: absolute;
  z-index: 0;
  opacity: 10%;
  right:-2vw;
}
.subD {
  margin: 0%;
  border: 0%;
  font-size: 1.2vw;
  font-family: "Montserrat", sans-serif;
font-weight: 400;
}

/* Foreground */
.SIboxCap{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: relative;
  top: 0vw;
}

.SIboxD{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.SIheadD {
  margin-top: 6vw;
  font-size: 2vw;
  font-weight: 500;
  font-style: normal;
  color: white;
}

#input1D {
  background-color: white;
  width: 28vw;
  height:2vw;
  border: none;
  border-radius: .5vw;
  box-shadow: .05em 0.1em .4em 0.1vw #7f7f7f;
  position: relative;
  padding-left: 2vw;
  font-family: inherit;
  font-size: 1vw;
}
#input2D {
  background-color: white;
  width: 28vw;
  height:2vw;
  border: none;
  border-radius: .5vw;
  box-shadow: .05em 0.1em .4em 0.1vw #7f7f7f;
  position: relative;
  padding-left: 2vw;
  font-family: "Montserrat", sans-serif;
  font-size: 1vw;
  margin-top: 1vw;
  
}

.errorD {
  font-size: .7vw;
  position:relative;
  padding: .5vw;
  padding-left: 1vw;
  padding-right: 1vw;
  width: fit-content;
  margin-top: 1vw;
  background-color: rgb(254, 204, 204);
  border-radius: .5vw;
  border: .2vw solid #ff0000;

}

.SIbuttonD {
  width: 100%;
  border: 0;
  height: 2.8vw;
  font-size: 1vw;
  border-radius:1vw;
  background-color: #000000;
  color: white;
  margin-top: 1.5vw;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
