
.createFull {
    position: fixed;
      left: 0vw;
      top: 0vw;
      background-color: white;
      width: 100vw;
      height: 100vh;
      z-index: 1000000;
  }
  
  .create-Cap {
    width: 85vw;
      height: 100vh;
      margin: 0%;
      padding: 0%;
      border: 0%;
      padding-left: 5%;
      position: fixed;
      z-index: 1101;
      top: 20vw;
      justify-content: center;
      align-items: center;
  }

  .inputU {
    border-bottom: .2vw solid #707070;
    margin-top: 5vw;
    border-color: #D3D3D3;
    border-width: .2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    font-weight: 600;
    font-size: 4.5vw;
    padding-bottom: 4vw;
    padding-left: 5vw;
    padding-right: 3vw;
  }

  .calCap {
    position: fixed;
    z-index: 1000000000000;
    background-color: white;
    width: 96vw;
    height: fit-content;
    left: 0vw;
    top: 50vw;
    border: 1.5px solid #707070;
    border-radius: 5vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-right: 2vw;
    padding-left: 2vw;

  }
  
  .createHead {
    margin: 0%;
  padding: 0%;
  border: 0%;
    
  font-size: 8vw;
  font-style: italic;
  font-weight: 700;
  position: relative;
  }
  .createSub {
    margin-top: 1.5vw;
  padding: 0%;
  border: 0%;
    
  font-size: 3.5vw;
  font-weight: 600;
  position: relative;
  margin-bottom: 5vw;
  }

  .inputT2 {
    margin-top: 5vw;
    border-left: none;
    border-right: none;
    border-top: none;
    border-color: #D3D3D3;
    border-width: .2vw;
    width: 98%;
    font-weight: 600;
    font-size: 4.5vw;
    padding-bottom: 4vw;
    padding-left: 5vw;
    font-family: "Montserrat", sans-serif;
    border-radius: 0vw;

  }

  .group22 {
        margin: 0%;
        padding: 0%;
        border: 0%;
        position: relative;
        width: fit-content;
        height: 6vw;
        border: 1.5px solid #707070;
        text-align: center;
        border-radius: 1.2vw;
        margin-right: 2vw;
        margin-top: 3vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        padding-top: .7vw;
        padding-bottom: .7vw;
        transition: .5s;
        -o-transition: .5s;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        border-color: #707070;
        display: flex;
        justify-content: center;
    
        align-items: center;
    
  }


.groupText22 {
    font-size: 2.7vw;
    font-weight: 600;
    position: relative;
    top: 0vw;

    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    color: #000000;
}

.qFlex{


    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7vw;
    
}

.q22 {

    font-size: 6.2vw;
    font-weight: 700;

}

.edit22 {
    font-size: 3.5vw;
    font-weight: 400;
    color: red;

}

.qFlex2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vw;
}

.newQ {

    font-size: 4.2vw;
    font-weight: 600;

}
.tta {
    font-size: 3vw;
    font-weight: 400;

}

.PCAP{
    position: fixed;
    bottom: 10vw;
    width: 100%;
    left: 0vw;
    display: flex;
    justify-content: center;
}

.PBUT {
    width: 50vw;
    height: 13vw;
    font-size: 4.5vw;
    font-weight: 600;
    border-radius: 4vw;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    background-color: #0072E2;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
}

.personnelText {
    font-weight: 700;
    font-size: 4.8vw;
    margin-top: 5vw;
}