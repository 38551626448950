.upModal {
    width:45vw;
    height: 30vh;
    background-color: white;
    z-index: 100000000000000000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    padding-top: 2vw;
    border-radius: 5vw;
    border: .25vw solid #707070;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  }
  
  .or {
    font-size: 2.2vw;
    font-weight: 700;
    padding-left: 4vw;
    padding-right: 4vw;
  }
  
  .pbIcon {
  
   
    width:13vw;
    height: 13vh;
    background-color: white;
    z-index: 100000000000000000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2vw;
    border: .1vw solid #d2d2d2;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  
  
  }
  
  .fileUp {
    width:13vw;
    height: 13vh;
    background-color: white;
    z-index: 100000000000000000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2vw;
    border: .1vw solid #d2d2d2;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  
  }