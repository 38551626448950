

.fullProfDD {
    background-color: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: .7;
    z-index: 10001000000000000000000;
}

.back-containerPV{
    position: fixed;
    top: 3.5vh;
    left: 2vw;
    z-index: 1000000000000000000;
    display: flex;
    align-items: center;
    width: 9vh;
    justify-content: space-between;
    color: white;
}

.backTxtProfD {
font-size: 2.5vh;
font-weight: 600;
}


.playPicView {
width: 40vw;
height: 100vh;

position: fixed;
left:30vw;
top: 0;
opacity: 1;
z-index: 1000000000000000000;
}

.customSwiper{
  width: 40vw;
  height: 100vh;
  
  position: fixed;
  left:30vw;
  top: 0;
  opacity: 1;
  z-index: 1000000000000000000;
  }

.playPicInner {
position: relative;
background-color: white;
top:10vh;
display: flex;
align-items: center;
height: 90vh;
width: 100%;
opacity: 1;
}

.playPic2D {
    height: auto;
    width: 100%;
    display: block;
    max-height: 90vh;
  }

  .topBar {
    position: fixed;
    width: 100%;
    height: 10vh;
    background-color: black;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 10000000000000000000;
  }

  .innerFlexTopBar {
    display: flex;
    width: 40vw;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 10000000000000000000;
    

  }


  .topBarTitle {
    color: white;
    font-weight: 600;
    font-size: 2.4vw;
    
  }

.topBarFolder {
    color: white;
    font-size: 3vh;
    font-weight: 400;

}

.flexColumnD {
    width: 13vh;
    position: fixed;
    height: 11.5vw;
    top: 2.5vh;
    right: 3vw;
    display: flex;
    justify-content: space-between;
   
  }

  .boxD{
    width: 5.2vh;
    height:5.2vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vh;
  
  }

  .commentsModalD {
    width: 23vw;
    height: fit-content;
    position: fixed;
    background-color: white;
    box-shadow: 0em 0.1em 0.5em 0.05vw #a0a0a0;
    right: 3vw;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 14vh;
    z-index: 10000000000000000000;
    padding-bottom: 4vh;
    
  }
  
  .commentTopFlexD {
    width: 85%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    top: 2.5vh;
  }
  
  .arrowCommentsD {
    transform: rotate(-90deg);
  }
  
  .commentsTextD {
    display: flex;
    width: 85%;
    position: relative;
    justify-content: center;
    flex-direction: column;
    top: 0vw;
  }
  
  
  .coachCommentBoxD {
    width:18vw;
    height: 0vw;
    border-radius: 1vw;
    position: relative;
    left: 1vw;
    box-shadow: 0em 0.1em .6em 0.05vw #a0a0a0;
    font-size: .8vw;
    top: 7vh;
    background-color: white;
    display: flex;
    align-items: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    margin-bottom: 4vw;
    
  }
  
  .coachCommentPicCapD{
  width: 2.5vw;
    height: 2.5vw;
    position: absolute;
    box-shadow: -0.00em 0.01em 0.5em 0.1vw #898989;
    border-radius: 10vw;
    overflow: hidden;
    left: -1vw;
    top: -1.5vh;
  }
  
  .coachCommentTextD{
    width: 81%;
    position: relative;
    left: 2vw;
  }
  
  .commentAddFlexD {
    width: 90%;
    height: 10vw;
    position: absolute;
    bottom: -2.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .addCommentD {
    width: 57vw;
    height: 4vh;
    border: none;
    background-color: white;
    border-radius: 1vw;
    padding-left: 7vw;
    color: #bcbcbc;
    font-weight: 100;
    box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  }
  
  .addComment2D {
    width: 14vw;
    height: 4vh;
    border: none;
    background-color: white;
    border-radius: 1.5vw;
    padding-left: 1.2vw;
    color: #2d2d2d;
    font-weight: 100;
    box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  }
  
  
  .addD {
    width: 4vw;
    height: 4vh;
    border-radius: 1vw;
    box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
    font-size: .8vw;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

  .playerCommentText {
    width: 87%;
    position: relative;
    left: 5.3vw;
  }
  .pCommentsArea{
    overflow:auto;
    height: 70vw;
    width: 105%;
    margin-bottom: 20vw;
  }

  .coachPlayPicD {
    width: 100%;
  }



.swiper-button-next {
  right: -40vw; /* Move right arrow further out */
}

.swiper-button-prev {
  left: -40vw; /* Move left arrow further out */
}

.navFlex {
  display: flex;
  justify-content: space-between;
  width: 47vw;
  position: fixed;
  top: 50vh;
  left: 26.5vw;
  height: fit-content;
  z-index: 100000000000000000000000000;
}


.playNavBox {
  width: 14vw;
  height: 4vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  border-radius: 2vw;
  font-size: 1.5vw;
  font-weight: 700;
  color: #0072E2;
  z-index: 100000000000000000000000000;
}

.PlayNavFlex {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  position: fixed;
  top: 87vh;
  left: 10vw;
  height: fit-content;
  z-index: 100000000000000000000000000;
  
}

.blackBack {
  position: fixed;
  z-index: 10000000000000000000000000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delBox {
  width: 30vw;
  height: 11vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  background-color: white;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  padding-bottom: 3vw;

  padding-left: 3vw;
  padding-right: 3vw;
  justify-content: space-between;
  text-align: center;
  font-size: 1.1vw;
}
.delBoxHead {
  font-size: 2.2vw;
  font-weight: 600;
}
.DPdel {
  width: fit-content;
  height: 3.5vw;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  border-radius: 1.5vw;
  font-weight: 600;
  position: relative;
  top: 1vw;

  padding-left: 2vw;
  padding-right: 2vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
}