
  .ProfList {
    position: relative;
    top: 1vw;
  }

  .divItemProfList{
    height: 4vw;
    width: 90%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 1.5vw;
    margin-left: .7vw;
  }

    
  .profPicProfList {
    width: 3.5vw;
    height: 3.5vw;
    position: relative;
    box-shadow: 0em 0.00em 0.3em 0.3vw #c3c3c3;
    border-radius: 3vw;
    overflow: hidden;
  }

  .nameProfList {
    position: relative;
    display: flex;
    width: 60%;
    left: 1.7vw;
    justify-content: space-between;
  }
  .picProfList {
    width: 100%;
  }

  .nameHeadProfList {
    font-size: 1.6vw;
    font-weight: 800;
  }
  .nameSubProfList {
    width: 100%;
    font-size: 1vw;
    font-weight: 500;
    position: relative;
    left: 0vw;
  }









.blackBackDelProfile {
  position: fixed;
  z-index: 10000000000000000000000000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delProfileBox {
  width: 30vw;
  height: 11vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  background-color: white;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  padding-bottom: 3vw;

  padding-left: 3vw;
  padding-right: 3vw;
  justify-content: space-between;
  text-align: center;
  font-size: 1.1vw;
}
.delProfileBoxHead {
  font-size: 2.2vw;
  font-weight: 600;
}
.DPdelProfile {
  width: fit-content;
  height: 3.5vw;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  border-radius: 1.5vw;
  font-weight: 600;
  position: relative;
  top: 1vw;

  padding-left: 2vw;
  padding-right: 2vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
}

.subTextDelProfile {
  font-size: 1.2vw;
}