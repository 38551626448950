.playNameHead {
    font-size: 7vw;
    font-weight: 600;
    color:white;
    }
    
    .playFolderHead {
      font-size: 3.5vw;
      font-weight: 400;
      font-style: italic;
      color:white;
      position: relative;
      bottom: 1.2vw;
    }
    
    .playPic2 {
      height: auto;
      width: 100%;
      display: block;
      max-height: 100vw;
    }

    .playCap {
        width: 90vw;
        height: fit-content;
        margin: 0%;
        padding: 0%;
        padding-left: 5vw;
          border: 0%;
          position: fixed;
          z-index: 1101;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
    

    
    
    .BottomFlex {
      width: 100%;
      height:30vw;
      display: flex;
      position: relative;
      top:-2vw;
      justify-content: space-between;
    }
    .captionBox{
      width:70vw;
      height: 27vw;
      border-radius: 5vw;
      position: relative;
      left: 4vw;
      box-shadow: 0em 0.1em 0.2em 0.4vw #a0a0a0;
    
      top: 3vw;
      background-color: white;
    }
    .captionBoxTopFlex{
      width: 75%;
      position: relative;
      right: 0vw;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      left: 13vw;
      top: 3vw;
    }
    
    .captionName{
      font-size: 4vw;
      font-weight: 600;
    }
    
    .captionDate{
      font-size: 2.5vw;
      font-weight: 600;
      position: relative;
      bottom: 1vw;
    }
    
    .captionText {
      width: 75%;
      position: relative;
      font-size: 2.7vw;
      font-weight: 300;
      left: 13vw;
      top: 4vw;
    }
    
    .flexColumn {
      width: 11.5vw;
      position: relative;
      height: 27vw;
      top: 3vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .box{
      width: 11.5vw;
      height:11vw;
      background-color: white;
      box-shadow: 0em 0.1em 0.2em 0.4vw #a0a0a0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4vw;
    
    }
    
    .star {
      position: relative;
      bottom:.1vw;
    }
    
    .coachPicPlayCap{
      width: 13vw;
      height: 13vw;
      position: absolute;
      box-shadow: -0.09em 0.01em 0.5em 0.3vw #484848;
      border-radius: 13vw;
      overflow: hidden;
      left: -4vw;
      top: -2vw;
    }
    
    .coachPlayPic {
      width: 100%;
    }
    
    .playPicCapVar {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      display: flex;
      position: fixed;
      left: 0;
      top: 0;
      flex-direction:column ;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10000000000;
    }
    
    .playPicVar {
      position: fixed;
      left: 0;
      width: 100%;
      height: auto;
    }
    
    