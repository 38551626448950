.pbBottomCapD {
    display: flex;
  flex-wrap: wrap;
  flex-direction: row; /* Default, for left-to-right flow */
  justify-content: flex-start; /* Align items starting from the left */
  gap: 2vw 2vw; /* Space between items, adjust as needed */
  padding: 1vw;
  overflow: auto;
  width: 54%;
  height: fit-content;
  max-height: 93%;
      
  }


  .playD{
    width: 5vw;
    height: 7vw;
    left: .5vw;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
     
  }

  .playsD {
    height: 90%;
    width: 54%;
    padding-right: 1vw;
    display: flex;
    position: relative;
    align-items: center;
  }

  .fileSystemGroupHead{
    position: relative;
    font-size: 2vw;
    font-weight: 700;
    top:3vw;
    left: 2vw;
  
  }



.triD {
  margin-left: .6vw;

  margin-top: .1vw;
}

.playbookOver{
  display: flex;
  width: 62%;
  position: relative;
  left: 3vw;
}

.playFolderHeadFlex {
  width: 100%;
  height: 65vh;
  box-shadow: .05em 0.1em .4em 0.1vw #5f5f5f;
  border-radius:1.7vw;
  

  
}





.innerSPAP {
  display: flex;
  position: relative;
  top: 8vw;
  justify-content: space-between;
  align-items: center;
  width: 96%;
}

.r {
  box-shadow: .02em 0.05em .6em 0.05vw #aeaeae;
  border-radius: 1vw;
outline: none;
}

.addSearch {
  display: flex;
  position: absolute;
  right: 2vw;
  top: 3vw;
  justify-content: space-between;
  width: 6.5vw;
}

.pbSplit {
  display: flex;
  width: 92%;
  position: relative;
  left: 5%;
  top: 12%;
  height: 72%;
}

.foldersD {
  height: 100%;
  width: 54%;
  padding-right: 1vw;
  border-right: 0.15vw solid rgba(195, 195, 195, 0.4);
  display: flex;
  position: relative;
  align-items: center;
}



.viewAllFilesD {
  font-size: 1vw;
  font-weight: 600;
  position: absolute;
  right: 9vw;
  color: #0072E2;
  bottom: 2.5vw;
}
.DeleteFolderD {
  font-size: 1vw;
  font-weight: 600;
  position: absolute;
  color: red;
  left: 11vw;
  bottom: 2.5vw;
  
}