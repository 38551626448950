.HomeLeftCol {
    width: 84%;
    height: fit-content;
    z-index: 0;
    padding: 8%;
    position: relative;
}

.profPicDesktop {
    width: 4vh;
    height: 4vh;
    position: absolute;
    box-shadow: 0em 0em .3em 0.15vh #adadad;
    border-radius: 2vh;
    overflow: hidden;
    left: 45vh;
  }

.greetingDesktop{
    font-size: 3.5vh;
    color: black;
    width: 70%;
    font-weight: 600;
    margin-top: 7vh;
}

.QuizNotificationsHome {
    width: 100%;
    position: relative;
}

.QuizNotificationsHomeHead {
    font-size: 2.8vh;
    letter-spacing: .08vh;
    color: black;
    font-weight: 500;
    margin-top: 4vh;
}

.QuizNotificationsHomeCap {
    position: relative;
    top: 1.5vh;
    overflow: auto;
    padding-bottom: 1.5vh;
}

.PlayNotificationsHomeCap {
    position: relative;
    top: 1.5vh;
}

.PlayNotificationsHome {
    width: 100%;
    position: relative;
}

.PlayNotificationsHomeHead {
    font-size: 2.8vh;
    letter-spacing: .08vh;
    color: black;
    font-weight: 500;
    margin-top: 3vh;
}

















