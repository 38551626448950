.CurrentQuizD {
    width: 100%;
    height: 10vw;
    position: relative;
    border-radius: 2vw;
    background-color: white;
    box-shadow: .05em 0.1em .6em 0.1vw #5f5f5f;
    margin-bottom: 2vw;
    margin-top: 2vw;
}



.quizNameCurrentD{
    position: absolute;
    font-size: 1.5vw;
    font-weight: 700;
    left: 1.2vw;

    top: 1.2vw;

}
.quizSubCurrentD{
    position: absolute;
    font-size: 1vw;
    font-weight: 300;
    left: 1.2vw;

    top: 3vw;

}
.timeLeftCurrentD{
    position: absolute;
    font-size: .8vw;
    font-weight: 500;
    left: 1.2vw;
    bottom: 1.2vw;
    color: red;
}

.GoQuizD {
    width: 8vw;
    background-color: #0072e2;
    height: 4vw;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1vw;
    bottom: 1vw;
    border-radius: 2vw;
}