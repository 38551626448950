body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.capsule {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom, #006bd6 32%, #FFFFFF);  
}

textarea:focus, input:focus {
  outline: none;
}

textarea
{
  resize: none;
}
