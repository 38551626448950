.selectFull {
    width:60vw;
    height: 52vw;
    background-color: white;
    position: fixed;
    left: 20vw;
    top: 70vw;
    z-index: 10000000000000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3vw;
    border-radius: 5vw;
    border: .25vw solid #707070;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
}

.selectHead {
    font-size: 6vw;
    font-weight: 800;
}

.select {
    border-radius: 2vw;
    width: fit-content;
    font-size: 4vw;
    font-weight: 600;
    padding: 2vw;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-top: 2vw;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    text-align: center;
}

.done {
    margin-top: 7vw;
    font-size: 4vw;
    font-weight: 700;
    background-color: #0072E2;
    width: 20vw;
    height: 10vw;
    color: white;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5vw;

}