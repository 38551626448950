.resultHeadD {
    width: 100%;
    margin-top: 2.6vw;
    padding-bottom: 1.5vw;
    border-bottom: 0.15vw solid #0072E2;
}
.resultHeadHead {
    font-size: 2.3vw;
    font-weight: 600;
}

.resultCircleD {
    width: 7vw;
    height: 7vw;
    border: 0.5vw solid #0072E2;
    border-radius: 5vw;
    font-size:1.8vw;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    
    right: 4vw;
    top: 6vw;
    


}


.outerQuestionResult {
    width: 100%;
    height: 68vh;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5vw;
  
  }

  .innerQuestionResult {
    height: 100%;
    overflow-x:visible;
    overflow-y:auto;
    width: 50%;
  }



  .viewByQuestion {
    font-size: 1.3vw;
    margin-bottom: .5vw;
    font-weight: 600;
  }







.resultQuizHead {
    font-size: 1.6vw;
    font-weight: 500;
    margin-top: 2vw;
}
.resultQuizSub {
    font-size: 1.1vw;
    font-weight: 300;
    margin-top: .3vw;
}


.fullResult {
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 1103;
    width: 100vw;
    height: 100vh;
  }
  
  .Result-Cap {
    width: 90vw;
    height: fit-content;
    margin: 0%;
    padding: 0%;
    padding-left: 5vw;
    border: 0%;
    position: fixed;
    z-index: 1101;
    left: 0;
    top: 7vw;
    background-color: white;
  }
  
  .resultTitle {
    font-size: 7.5vw;
    font-weight: 800;
    width: 60vw;
    position: relative;
  }
  
  .resultSub {
    font-size: 4vw;
    margin-top: 1vw;
    font-weight: 500;
    width: 40vw;
    position: relative;
  }
  
  .blueCircleResult {
    position: absolute;
    top: -1vw;
    right: 0vw;
    width: 18vw;
    height: 18vw;
    border-radius: 18vw;
    border: 2vw solid #ff0000;
    font-weight: 600;
    font-size: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .swiperHeadFlex {
    font-size: 5.2vw;
    width: 100%;
    display: flex;
    font-weight: 600;
    justify-content: space-around;
    position: relative;
    top: 10vw;
  }
  
  .yr {
    border-bottom: 1vw solid #0072e2;
    padding-bottom: 1.5vw;
  }
  
  .swipeOverall {
    position: relative;
    top: 16vw;
    overflow: hidden;
    padding-bottom: 5vw;
  }
  

  
  .questionWrapperD {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    position: relative;
    margin-bottom: 0vw;
  }
  
  .questionBoxCircle2D {
    display: flex;
    width: 96%;
    height: 6vw;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.5vw;
  }
  
  .questionCircle2D {
    width: 2vw;
    height: 2vw;
    border-radius: 3vw;
    border: .2vw solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3vw;
    font-weight: 700;
    box-shadow: -0.05em 0.1em 0.2em 0.1vw #a8a8a8;
  }
  
  .questionBox2D {
    height: 2vw;
    width:17vw;
    border-radius: 1vw;
    box-shadow: -0.05em 0.1em 0.2em 0.1vw #aaaaaa;
    font-size: 2vw;
    padding: 1vw;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    align-items: center;
    background-color: #E7E7E7;
  }
  
  .teammatesWrapperD {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
    top: .3vw;
  }
  
  .teammateFlexD {
    display: flex;
    width: 100%;
    height: 3.5vw;
    align-items: center;
    padding-left: 0.5vw;
    position: relative;
    overflow: hidden;
  }
  
  .teammateCircleD {
    width: 2vw;
    height: 2vw;
    box-shadow: -0.05em 0.1em 0.3em 0vw #000000;
    border-radius: 1vw;
    overflow: hidden;
  }
  
  .teammatePicD {
    width: 100%;
  }
  
  .teammateNameD {
    font-size: 1.2vw;
    font-weight: 500;
    position: relative;
    left: 1vw;
  }
  
  .teammatePCTD {
    font-size: 1.2vw;
    font-weight: 700;
    position: absolute;
    right: 1vw;
  }
  
  #sTextQ {
    font-size: 3vw;
  }
  #mTextQ {
    font-size: 3.4vw;
  }
  #lTextQ {
    font-size: 4vw;
  }
  
  .returnToHomeCap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40vw;
    display: flex;
    justify-content: center;
    background: linear-gradient(to top, rgb(106, 106, 106), rgba(0, 0, 0, 0));
    z-index: 1000000000000000000;
  }
  .backToHome {
    width: 45vw;
    height: 15vw;
    border-radius: 5vw;
    background-color: #0072e2;
    display: flex;
    position: relative;
    margin-top: 15vw;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 4.2vw;
    font-weight: 500;
    box-shadow: 0em 0.05em 0.4em 0.05vw #2b2b2b;
  }
  
  
  .QBflexD{
  display: flex;
  justify-content: space-between;
  width: 97%;
  align-items: center;
  }
  .avgTXTD{
    font-size: 1.1vw;
  }
  .srBoxD {
    width: 6vw;
    height: 2.5vw;
    background-color: #0072E2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8vw;
    border-radius: 1vw;
    padding: .5vw;
    padding-top: 0vw;
    padding-bottom: 0vw;
  
  
  }