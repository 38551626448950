.fullSBQ {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000000000000;
}

.topBarSBQ {
    position: fixed;
    width: 100%;
    height: 10vh;
    background-color: black;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 10000000000000000000;
  }

.backContainer-SBQ {
  position: fixed;
  top: 3.5vh;
  left: 2vw;
  z-index: 1000000000000000000;
  display: flex;
  align-items: center;
  width: 9vh;
  justify-content: space-between;
  color: white;
}

.backTxtSBQ {
  font-size: 2.5vh;
  font-weight: 600;
}

.innerFlexTopBarSBQ {
  display: flex;
  width: 40vw;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10000000000000000000;
  position: relative;
  bottom: 1.5vw;
}

.topBarTitleSBQ {
  color: white;
  font-weight: 600;
  font-size: 2.4vw;
}

.SBQOver {
    width: 100vw;
    height: 90%;
    position: fixed;
    z-index: 10000000000000000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 0;
    top: 10%;
  }


  .questionOverSBQ {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .SBQQuestion {
    font-size: 1.7vw;
    padding: 1vw;
    font-weight: 600;
    color: white;
    height: 6%;
    padding-top: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .playPicCapDQuiz {
    width: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 62%;
  }
  
  .playPicDQuiz {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }
  
  .answersCD{
    width: 100%;
    height: 8%;
    position: relative;
    margin-top: 1.5vw;
    display: flex;
    justify-content: space-between;
    }
  
  .answerCD{
    width: 44%;
    height: 100%;
    border: .2vw solid black;
    border-radius: 1vw;
    position: relative;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    text-align: center;
    font-size: 1vw;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    overflow: hidden;
    padding-right: 1vw;
    padding-left: 1vw;
  }
  
  .unAnswer2CD{
  width: 44%;
  height: 100%;
  border: .2vw solid rgb(12, 215, 12);
  border-radius: 1vw;
  position: relative;
  box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  text-align: center;
  font-size: 1vw;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  overflow: hidden;
  padding-right: 1vw;
  padding-left: 1vw;
  }







.topBarQuestions {
  color: white;
  font-size: 3vh;
  font-weight: 400;
}

.flexColumnCurrentQuiz {
  width: 13vh;
  position: fixed;
  height: 11.5vw;
  top: 2.5vh;
  right: 0vw;
  display: flex;
  justify-content: space-between;
}


.playersQuestion {
  position: fixed;
  width: 22vw;
  height: 50vh;
  background-color: white;
  right: 6vw;
  top: 22.9vh;
  border-radius: 2vw;
  padding-left: 2vw;
}
.PlayersHeadtxtD {
  font-size: 2vw;
  font-weight: 700;
  margin-top: 2vw;
}

.oFlexPlayersD {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 45vw;
  overflow: auto;
  margin-top: 1vw;
}

.iiFlexPlayerDC {
  display: flex;
  width: 100%;
  align-items: center;
  height: 3.5vw;
}

.iiFlexPlayerD {
  display: flex;
  width: 100%;
  align-items: center;
  height: 3.5vw;
  position: relative;
  left: 0.2vw;
}

.playerResTxtD {
  font-size: 1.5vw;
  font-weight: 500;
  margin-left: 1.5vw;
}
.playerResTxtDC {
  font-size: 1.5vw;
  font-weight: 500;
  margin-left: 1.05vw;
}
