:root {
  --color-primary: white;
  --color-secondary: ;
  --color-tertiary: ;
  --color-quaternary: ;
}



/*  Background  */



.SIApp {
  text-align: left;
  margin-left: 0%;
  width: 100vw;
  padding: 0%;
  border: 0%;
  height: 100vh;
  justify-content: center;
  font-family: "Montserrat", sans-serif;

}

.GPCap{
  width: 100vw;
  position: relative;
  top: 21vw;
  height: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;

}

.headCap{
  width: 100vw;
  position: relative;
  top: 20vw;
  height: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;

}

.subCap{
  width: 100vw;
  position: relative;
  top: 13.5vw;
  color: white;
  height: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  padding-top: 4.5%;
}
.logo2 {
  position: absolute;
  z-index: 0;
  opacity: 10%;
  right:-2vw;
}
.sub {
  margin: 0%;
  border: 0%;
  font-size: 4.5vw;
  font-family: "Montserrat", sans-serif;
font-weight: 400;
}

/* Foreground */

.SIbox{
  width: 80vw;
  z-index: 20;
  position: relative;
  left: 10vw;
  top: 20vw;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  
}

.SIhead {
  margin-top: 6vw;
  font-size: 7vw;
  font-weight: 500;
  font-style: normal;
  color: white;
}

#input1 {
  background-color: white;
  width: 94.5%;
  height:9vw;
  top: 2vw;
  border: none;
  border-radius: 2.5vw;
  box-shadow: .05em 0.1em .4em 0.1vw #7f7f7f;
  position: relative;
  padding-left: 4vw;
  font-family: inherit;
  font-size: 3.5vw;
}
#input2 {
  background-color: white;
  width: 94.5%;
  height:9vw;
  margin-top: 6vw;
  border: none;
  border-radius: 2.5vw;
  box-shadow: .05em 0.1em .4em 0.1vw #7f7f7f;
  position: relative;
  padding-left: 4vw;
  font-family: "Montserrat", sans-serif;
  font-size: 3.5vw;
  
}

.error {
  font-size: 2.5vw;
  position:relative;
  padding: 2vw;
  padding-left: 4vw;
  padding-right: 4vw;
  width: fit-content;
  margin-top: 5vw;
  background-color: rgb(254, 204, 204);
  border-radius: 2vw;
  border: .3vw solid #ff0000;

}

.SIbutton {
  width: 100%;
  border: 0;
  height: 12vw;
  font-size: 4vw;
  border-radius:4vw;
  background-color: #000000;
  color: white;
  margin-top: 5vw;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

/* Footer */

.termFlex {
  display: flex;
  width: 87vw;
  flex-direction: row;
  align-items: center;
  height: 10vw;
  position: absolute;
  font-size: 3vw;
  justify-content: space-between;
  top: 92vh;
  left: 6%;
}

 #item {
  border: 0;
  border-left: .4vw solid #DEDEDE;
  padding-left: 4vw;
  margin: 0vw;
  background-color: transparent;
  

 }
 #item1 {
  border: 0;
  background-color: transparent;

 }

 .capsuleMobile {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom, #006bd6 32%, #FFFFFF);  
}
.landscape{
  display: none;
}


@media (orientation:landscape){

  .landscape {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #006edd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-weight: 300;
    color: white;
  }
  
  .capsuleMobile {
    display: none;
  }

}


