.fullQuestion{
    position: fixed;
    display:flex;
    justify-content: center;
    
    left: 0;
    top: 0;
    background-color: white;
    z-index: 10000000000000000001;
    width: 100vw;
      height: 100vh;

}

.back-containerResult {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 14vw;
    top: 10vw;
    left: 5vw;
  }
  
  .backTxtResult {
    font-weight: 600;
    font-size: 4vw;
    position: relative;
  }

.QuestionReview {
    width:90%;
    height: 150vw;
    position: relative;
    top: 20vw;
}

.PnQReview {
    height: 80vw;
    width: 100%;
    border-radius: 5vw;
    border: .4vw solid #000000;
    position: relative;
    top: 5vw;
    left: -.7vw;
    border-width: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-bottom: 5vw;
}

.inP {
    position: relative;
    margin-top: 3vw;
    display: flex;
    top: 1vw;
    width: 90vw;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.questionText {
width: 80vw;
text-align: center;
position: relative;
font-size: 3.4vw;
font-weight: 600;

}

.playPicCap {
    width: 80vw;
    height: 60vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
}



.playPic {
    width: auto;
    height: 100%;
    display: block;
}


.answers{
width: 100%;
height: 60vw;
position: relative;
top: 8vw;
display: flex;
justify-content: space-between;
flex-direction: column;
}


.answerResult{
    width: 100%;
    height: 20vw;
    border: .4vw solid #CACACA;
    border-radius: 3vw;
    position: relative;
    margin-top: 2vw;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    text-align: center;
    font-size: 4vw;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.unAnswerResult{
    
    width: 100%;
    height: 20vw;
    border: 1vw solid #CACACA;
    border-radius: 3vw;
    position: relative;
    margin-top: 2vw;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    text-align: center;
    margin-left: -.6vw;
    font-size: 4vw;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


#xsText {
    font-size: 3vw;
    padding-top: 1vw;
}
#sText {
    font-size: 3.4vw;
}
#mText {
    font-size: 3.8vw;
}
#lText {
    font-size: 4.2vw;
    padding-bottom: 1vw;
}
#xlText {
    font-size: 5vw;
    padding-bottom: 2vw;
}


.innerA {
    width:94%;
    position: relative;
    left:3vw;
}

#sTextA {
    font-size: 3vw;
}
#mTextA {
    font-size: 3.4vw;
}
#lTextA {
    font-size: 4.2vw;
}