.exitModal {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 100000000;
}

.exitModal-Cap {
    height: 65vw;
    width: 60vw;
    overflow: hidden;
  position: fixed;
  left:15vw;
  background-color: white;
  z-index: 1000000000011111;
border: .4vw solid #CACACA;
box-shadow: -0.05em 0.1em 0.4em .2vw #757575;
text-align: center;
font-size: 4vw;
font-weight: 500;
border-radius: 5vw;
padding-left: 5vw;
padding-right: 5vw;
}

.modalHeadText {
font-size: 6vw;
font-weight: 700;
position: relative;
top: 7vw;
}

.modalSubText {
    font-size: 3.7vw;
font-weight: 500;
position: relative;
top: 12vw;
}

.modalMainButton {

    font-size: 4.5vw;
font-weight: 600;
position: relative;
top: 18vw;
padding: 3vw;
color: #FF0000;
border: .4vw solid #FF0000;
width: 20vw;
left: 16.4vw;
border-radius: 3vw;

}

.modalSubButton {
    font-size: 3.7vw;
font-weight: 500;
position: relative;
top: 22vw;
    
}