
  .profListLeaderboard {
    position: relative;
    top: 1vw;
  }

  .profileLeaderboard{
    height: 4vw;
    width: 90%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 1.5vw;
    margin-left: .7vw;
  }

  .profPicLeaderboard {
    width: 3.5vw;
    height: 3.5vw;
    position: relative;
    box-shadow: 0em 0.00em 0.3em 0.3vw #c3c3c3;
    border-radius: 3vw;
    overflow: hidden;
  }

  .picLeaderboard {
    width: 100%;
  }

  .nameLeaderboard {
    position: relative;
    display: flex;
    width: 60%;
    left: 1.7vw;
    justify-content: space-between;
  }

  .nameHeadLeaderboard {
    font-size: 1.6vw;
    font-weight: 800;
  }
  .nameSubLeaderboard {
    width: 100%;
    font-size: 1vw;
    font-weight: 500;
    position: relative;
    left: 0vw;
  }

  .timeLeaderboard{
      position: fixed;
      right: 4vw;
      font-size: 1vw;
      font-weight: 600;
  }














  .profList3D {
    position: relative;

  }

  .hollowDotD {
    width: 4vw;
    height: 4vw;
    border: .6vw solid black ;
    border-radius: 4vw;
  }


  
  .div-item2D{
    height: 4vw;
    width: 70%;
    display: flex;
    align-items: center;
    margin-bottom: 1vw;
    margin-top: 1.5vw;
  }
  

  



  
  .name3D {
    position: relative;
    display: flex;
    width: 60%;
    left: 1.5vw;
    justify-content: space-between;
    align-items: center;
  }
  

  
