
.LeaderboardDesktop {
  margin: 0%;
  padding: 0%;
  border: 0%;
  height: fit-content;
  width: 100%;
  height: 20vw;
  top: -3vw;
  position: relative;
  left: 0vw;
}

.leaderboardBody {
  position: relative;
  margin-top: 2.5vw;
}

.navHeadLeaderboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  position: relative;
  font-weight: 600;
  font-size: 1.9vw;
}




.HeaderD {
  position: relative;
}



.LeaderboardD {
  width: 85%;
  margin: 0%;
  position: relative;
  padding: 0%;
  border: 0%;
  font-size: 3vw;
  font-style: italic;
  font-weight: 700;
  position: relative;
}

.navItem1LB {
  padding-bottom: .5vw;
}

.navItem2LB {
  padding-bottom: .5vw;
}

.navItem3LB {
  padding-bottom: .5vw;
}


