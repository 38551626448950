.createHeadDesktop {
  margin: 0%;
padding: 0%;
border: 0%;
margin-top: .5vw;
  
font-size: 2vw;
font-style: italic;
font-weight: 700;
position: relative;
}
.createSubDesktop {
  margin-top: .3vw;
padding: 0%;
border: 0%;
  
font-size: 1.5vw;
font-weight: 600;
position: relative;
margin-bottom: 2vw;
}

.inputCreateQuestion {
  margin-top: 1vw;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: .1vw solid black;
  width: 95%;
  font-weight: 500;
  font-size: 1.1vw;
  padding-bottom: 1vw;
  padding-left: 1vw;
  font-family: "Montserrat", sans-serif;
  border-radius: 0vw;
  height: 2vw;

}

.uploadImageCreateQuestion {
  border-bottom: .1vw solid black;
  margin-top: 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 91.8%;
  font-weight: 500;
  font-size:1.1vw;
  padding-bottom: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  position: relative;
  height: 2vw;
}

.postButtonCreateQuestion {
  width: 50%;
  height: 3.5vw;
  font-size: 1.2vw;
  font-weight: 600;
  border-radius: 1vw;
  box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  background-color: #0072E2;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 1vw;
  left:25%;
}
  


