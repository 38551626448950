.overallSPAP{
    width: 88vw;
  height: 100vh;
  position: relative;
  left: 12vw;
  }

  .playbookHead {
    font-size: 3vw;
    font-weight: 700;
    margin-left: 3vw;
    position: relative;
    top: 4vw;
  }


  .playbookSubFlex {
    display: flex;
    width: fit-content;
    height: fit-content;
    position: relative;
    justify-content: space-between;
    align-items: center;
    top: 4.2vw;


}


.playbookSub {
    font-size: 1.5vw;
    font-weight: 500;
    margin-left: 3vw;
    position: relative;
    color: #93A3BB;
  }