
.App1 {
  margin: 0%;
  padding: 0%;
  border: 0%;
  height: fit-content;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0vw;
  left: 0vw;
}

.Header {
  position: relative;
  top: 20vw;
  left: 7.5vw;
}



.Leaderboard {
  top: 20vw;
  width: 85%;
  left: 7.5%;
  margin: 0%;
  padding: 0%;
  border: 0%;
  font-size: 9.5vw;
  font-style: italic;
  font-weight: 800;
  position: relative;
  padding-bottom: 2vw;
    border-bottom: 0.35vw solid rgb(211, 211, 211);
}


.bod {
  position: relative;
  top:10vw;
}

.navHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  position: relative;
  top: 17vw;
  font-weight: 300;
  font-size: 4.6vw;
  left: 7.5vw;
}



.navItem1 {
  padding-bottom: .5vw;
}

.navItem2 {
  padding-bottom: .5vw;
}

.navItem3 {
  padding-bottom: .5vw;
}


