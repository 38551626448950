
.blackBackProfile {
  position: fixed;
  z-index: 10000000000000000000000000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileBox {
  width: 30vw;
  height: 15vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  background-color: white;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  padding-bottom: 2vw;

  padding-left: 3vw;
  padding-right: 3vw;
  text-align: center;
  font-size: 1.1vw;
}
.ProfileBoxHead {
  font-size: 2.4vw;
  font-weight: 600;
  margin-top: 3vw;
}
.DPProfile {
  width: fit-content;
  height: 3.5vw;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  border-radius: 1.5vw;
  font-weight: 600;
  position: relative;
  margin-top: 3vw;

  padding-left: 2vw;
  padding-right: 2vw;
  box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
}

.subTextProfile {
  font-size: 1.2vw;
  margin-top: 1vw;
}

.ProfilePicModal {
  position: absolute;
  width: 10vw;
  height: 10vw;
  border-radius: 5vw;
  overflow: hidden;
  top: 21vw;
}