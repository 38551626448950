

.groupOver {  
  z-index: 100000000000000000;  

  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, .6);
}
.groupInner {
  width: 45vh;
  height: 60vh;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4vh;
  box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  flex-direction: column;
  padding: 4vh;
position: relative;
} 

.groupHead {
  margin: 0%;
border: 0%;
  width: 95%;
font-size: 3.8vh;
font-style: italic;
font-weight: 700;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
}
.PlayersOrCoachFlex {
  display: flex;
  justify-content: space-between;
}

.GroupSub{
    padding: 0%;
    border: 0%;
    margin-right: 3vh;
    font-size: 2.3vh;
    font-weight: 600;
    position: relative;
    padding-bottom: .3vh;
    
}

.GroupFlexSub{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 95%;
    margin-top: 2vh;
}

.groupEdit {
  font-size: 1.8vh;
  font-weight: 400;
  color: red;

}

.addClass{
  outline: none;
}








  
.BackButtonGroup {
  width: 45%;
  height: 3.5vw;
  font-size: 1.2vw;
  font-weight: 600;
  border-radius: 1vw;
  box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
  background-color: #0072E2;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  
  
}

.BackButtonGroupOuter {
  width: 100%;
  display: flex;
  justify-content: center;
  height: fit-content;
  bottom: 3vw;
  left: 0;
  position: absolute;

}