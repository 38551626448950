.timeSelectFullDesktop {
    width:25vw;
    height: 30vh;
    background-color: white;
    z-index: 100000000000000000000000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vw;
    border-radius: 5vw;
    border: .25vw solid #707070;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
}

.selectHeadD {
    font-size: 2.2vw;
    font-weight: 800;
}

.selectD {
    border-radius: 1vw;
    width: fit-content;
    font-size: 1.5vw;
    font-weight: 600;
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: .5vw;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    text-align: center;
}

.timeSelectApply {
    margin-top: 6vh;
    font-size: 1.4vw;
    font-weight: 700;
    background-color: #0072E2;
    width: 10vw;
    height: 5vh;
    color: white;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vw;

}