.SearchMod {
    width: 30%;
    height: 35vw;
    background-color: white;
    position: fixed;
    left: 35vw;
    top: 17vh;
    z-index: 100000000000000000000000000000000000000000000;
    border-radius: 2vw;
    box-shadow: .02em 0.05em .6em 0.05vw #aeaeae;
    display: flex;
    flex-direction: column;
    padding: 2vw;

}

.search-boxD {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.1vw solid rgb(0, 0, 0);
  }
  
  .pbSearchbarD {
    width: 78vw;
    font-size: 1.5vw;
    border: 0;
    background-color: transparent;
    font-weight: 500;
    z-index: 901;
  }

  .RFSBottomCapDD {
    width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: auto;
      top: 1.2vw;
      left: .3vw;
  }