

.NewProfileOver {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000000000000000000000000000;
    background-color: rgb(0, 0, 0, .6);
        display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
}
.NewProfileInner {
    width: 45vh;
    height: 70vh;
    align-items: center;
    background-color: white;
    border-radius: 3vw;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    padding: 4.5vh;
    position: relative;
    display: flex;
    flex-direction: column;

} 
.NewProfileHead {
    font-weight: 700;
    font-size: 4vh;
}

.newProfileIn {
    position: relative;
    margin-top: 2.5vh;
}

.inputNewProfile {
    margin-top: 1.4vh;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: .14vh solid black;
    width: 95%;
    font-weight: 500;
    font-size: 1.6vh;
    padding-bottom: 1.4vh;
    padding-left: 1.5vh;
    font-family: "Montserrat", sans-serif;
    border-radius: 0vw;
    height: 3vh;
  
  }

  .UploadNewProfile {
    border-bottom: .14vh solid black;
    margin-top: 1.4vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 92.2%;
    font-weight: 500;
    font-size:1.6vh;
    padding-bottom: 1.4vh;
    padding-left: 1.5vh;
    padding-right: 1.5vh;
    position: relative;
    height: 3vh;
  }







.AddButtonNewProfile {
    width: 45%;
    height: 5vh;
    font-size: 1.8vh;
    font-weight: 600;
    border-radius: 1.5vh;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    background-color: #0072E2;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    
    
}

.AddButtonNewProfileOuter {
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    bottom: 4vh;
    left: 0;
    position: absolute;

}