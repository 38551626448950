.pFilter {
    width: 60vw;
    height: 86vw;

    background-color: white;
    margin: auto;
    padding: 0 .7rem;
    border-radius: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1301;
}

.pfCap {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 90%;
    height: 29vw;
    justify-content: space-between;

}

.pfHead {
    margin-top: 12%;
    font-size: 6.3vw;
    font-weight: 700;

}

.pfSub {
    font-size: 3.3vw;
    font-weight: 600;
    position: relative;
    top: 8vw;
    
}

.groups {
    width: 55vw;
    height: 15vw;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.group{
    margin: 0%;
    padding: 0%;
    border: 0%;
    position: relative;
    width: fit-content;
    height: 4.5vw;
    border: 1.5px solid #707070;
    text-align: center;
    border-radius: 1.2vw;
    margin-right: 2vw;
    margin-top: 1.5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-top: .7vw;
    padding-bottom: .7vw;
    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    border-color: #707070;
    display: flex;
    justify-content: center;

    align-items: center;
}


.groupText {
    font-size: 2.2vw;
    font-weight: 600;
    position: relative;
    top: 0vw;

    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    color: #000000;
}


.flexButton {
    position: relative;
    top: 30vw;

}

.goBut {
    color: #5259FB;
    background-color: transparent;
    border: none;
    font-weight: 800;
    font-size: 4.2vw;
}