  .overallQuestionBox {
    width: 95%;
    height: fit-content;
    border-radius: 2vw;
    background-color: white;
    box-shadow: 0.1em 0.1em 0.2em 0.2vw #d1d1d179;
    margin-top: 1vw;
    margin-left: 0.5vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1fr;
  
  }

  .questionHeader {
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    margin: 8px
  }
  
  .QnumDesktop {
    width: 2.25vw;
    height: 2.2vw;
    background-color: #0072E2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    border-radius: 2vw;
    font-size: 1vw;
    font-weight: 500;
    margin-right: .8vw;
    margin-left: 1vw;
  }
  
  .QnumDesktop2 {
    width: 2vw;
    height: 2vw;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    border-radius: 2vw;
    font-size: 1vw;
    font-weight: 500;
    margin-right: .8vw;
    margin-left: 1vw;
    border: 0.15vw solid rgba(0, 0, 0);
  }

  .selected {
    background-color: #ed352b;
  }
  
  .questionPrevText{
    font-size: .9vw;
    font-weight: 500;
  
  }
  
  .timeForQuestion {
    position: absolute;
    right: 1vw;
    font-size: .9vw;
    font-weight: 700;
  
  }

  ::-webkit-scrollbar {
    display: none;
}

.qItemContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  align-items: center;
  gap: 1vh;
}

.qPicture {
  width: calc(100% - 10vw);
  height: auto;
  border-radius: 20px;
  object-fit: cover;
  align-self: center;
}

.questionItem {
  border: red solid 2px;
  border-radius: 12px;
  margin: 0;
  padding: 10px;
  text-wrap:pretty;
  word-wrap: break-word;
  width: 85%;
  box-sizing: border-box;
}


.qItemContainer > .questionItem:first-of-type {
  border-color: green;
}