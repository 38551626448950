.appSPA {
    margin: 0%;
    padding: 0%;
    border: 0%;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    position: fixed;
    overflow: hidden;
}


.profPic {
    width: 13vw;
    height: 13vw;
    position: fixed;
    box-shadow: -0.09em 0.01em 0.5em 0.3vw #484848;
    border-radius: 13vw;
    overflow: hidden;
    top: 8vw;
    left: 78vw;
  }

  .profPic2 {
    width: 13vw;
    height: 13vw;
    position: fixed;
    box-shadow: -0.09em 0.01em 0.5em 0.3vw #484848;
    border-radius: 13vw;
    overflow: hidden;
    top: 8vw;
    left: -78vw;
  }
  
  .pic {
    width: 100%;
  }

  #questionSwiperItem {
    height: 2vw;
  }

