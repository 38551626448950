.footD {
    height: 100vh;
    width: 30vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: center;
    border-radius: 2.2vh;
    box-shadow: 0em 0em 0.8em 0.15vh #b9b9b9;
    z-index: 0;
    left: -13vh;
    top:0vw;
  }
  
  .iconFlexD {
    display: flex;
    flex-direction: column;
    z-index: 10;
    justify-content: space-between;
    height: 45vh;
    position: relative;
    left: 6.5vh;
    top: 3vh;
  }

  .logo3 {
    position: relative;
    left: 6.5vh;
  }

  .navHeader {
position: absolute;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
top: 1.5vh;
  }