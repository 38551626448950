
.CreateSPA{
  width: 88vw;
height: 100vh;
position: relative;
left: 12vw;
display: flex;
align-items: center;
}
  .create-Cap-Desktop {
    width: 100%;
      height: 100%;
      margin: 0%;
      padding: 0%;
      border: 0%;
      z-index: 1101;
      position: relative;
      justify-content: center;
      align-items: center;
  }

  .createHeadDesktop {
    margin: 0%;
  padding: 0%;
  border: 0%;
  margin-top: .5vw;
    
  font-size: 2vw;
  font-style: italic;
  font-weight: 700;
  position: relative;
  }
  .createSubDesktop {
    margin-top: .3vw;
  padding: 0%;
  border: 0%;
    
  font-size: 1.5vw;
  font-weight: 600;
  position: relative;
  margin-bottom: 2vw;
  }

  .inputCreateQuiz {
    margin-top: 1vw;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: .1vw solid black;
    width: 95%;
    font-weight: 500;
    font-size: 1.1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    font-family: "Montserrat", sans-serif;
    border-radius: 0vw;
    height: 2vw;

  }

  .uploadImageCreateQuiz {
    border-bottom: .1vw solid black;
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 91.8%;
    font-weight: 500;
    font-size:1.1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    position: relative;
    height: 2vw;
  }

  .personnelTextCreateQuiz {
    font-size: 1.2vw;
    font-weight: 500;
    margin-top: 2vw;
    position: relative;
  }

  .groupCreateQuiz{
    margin: 0%;
    padding: 0%;
    border: 0%;
    position: relative;
    width: fit-content;
    height: 1vw;
    border: 1.5px solid #707070;
    text-align: center;
    border-radius: .8vw;
    margin-right: 2vw;
    margin-top: .7vw;
    padding-left: .6vw;
    padding-right: .6vw;
    padding-top: .5vw;
    padding-bottom: .5vw;
    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    border-color: #707070;
    display: flex;
    justify-content: center;
  
    align-items: center;
  
  }

  .groupTextCreateQuiz{
    font-size: .7vw;
    font-weight: 600;
    position: relative;
    top: 0vw;
  
    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    color: #000000;
  }

  .postButtonCreateQuiz {
    width: 50%;
    height: 3.5vw;
    font-size: 1.2vw;
    font-weight: 600;
    border-radius: 1vw;
    box-shadow: -0.05em 0.1em 0.15em 0vw #757575;
    background-color: #0072E2;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    bottom: 1vw;
    left:25%;
  }
  
  .calCapD {
    z-index: 100000000000000000000000000;
    background-color: white;
    width: 40vw;
    height: fit-content;
    border: 1.5px solid #707070;
    border-radius: 5vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-right: 2vw;
    padding-left: 2vw;

  }


