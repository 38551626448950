.GroupList {
    position: relative;

  }

  .GroupListItem{
    height: 4vw;
    width: 70%;
    display: flex;
    align-items: center;
    margin-bottom: 1vw;
    margin-top: 1.5vw;
  }

  .nameGroupList {
    position: relative;
    display: flex;
    width: 60%;
    left: 1.5vw;
    justify-content: space-between;
    align-items: center;
  }

  .nameHeadGroupList {
    font-size: 1.6vw;
    font-weight: 800;
  }
  .nameSubGroupList {
    width: 100%;
    font-size: 1vw;
    font-weight: 500;
    position: relative;
    left: 0vw;
  }