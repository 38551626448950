.appSPA2 {
    margin: 0%;
    padding: 0%;
    border: 0%;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    position: fixed;
    overflow: hidden;
    background-color: white;
}







  .pic {
    width: 100%;
  }












