.QuizNotification {
    display:flex;
    width: 100%;
    height: 8vh;
    align-items: center;
    position: relative;
    padding-top: 3vh;
    margin-bottom:4vh;
}

.QuizNotificationDot {
    margin-left: 5.3vh;
    margin-right: 5.3vh;
    width: 1.4vh;
    height: 1.5vh;
    background-color: red;
    border-radius: 1.5vh;
    box-shadow: 0em 0em .2em 0.05vh #bdbdbd;
}

.QuizNotificationBox {
    width: 32vh;
    height: 12vh;
    position: relative;
    right: .6vh;
    border-radius: 3vh;
    background-color: white;
    box-shadow: .05em 0.1em .2em 0.08vh #c9c9c9;
}

.QuizNotificationTitle{
    position: absolute;
    font-size: 1.7vh;
    font-weight: 700;
    left: 2.2vh;
    top: 2.2vh;

}
.QuizNotificationSub{
    position: absolute;
    font-size: 1.5vh;
    font-weight: 300;
    left: 2.1vh;

    top: 4.5vh;

}
.QuizNotificationSubmitted{
    position: absolute;
    font-size: .8vh;
    font-weight: 500;
    left: 2.4vh;
    top: 9vh;
    color: red;
}

.QuizNotificationIcon {
    position: absolute;
    right: 2vh;
    top: 6vh;
}