.PastQuizD {
    
    display:flex;
    width: 100%;
    align-items: center;
    position: relative;
    border-radius: 1vw;
    background-color: white;
    box-shadow: .05em 0.1em .6em 0.1vw #5f5f5f;
    height: 4vw;
    margin-bottom: 1vw;
}

.checkD {
    padding-left: 1vw;
}

.PastQuizNameD {
    font-size: 1.2vw;
    font-weight: 500;
    margin-left: .7vw;

}

.PastQuizScoreD {
    position: absolute;
    font-size: 1vw;
    font-weight: 700;
    margin-left: 7vw;
    right: 2.5vw;

}