.RecommendedPlays {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; /* Default, for left-to-right flow */
    justify-content: flex-start; /* Align items starting from the left */
  }
  .RLSFlexCapD {
    display: flex;
    width: 98%;
    height: 13vw;
    position: relative;
    justify-content: space-between;
    top: .5vw;
  }
  .RecommendedPlay{
    width: 11.5vw;
    height: 10vw;
    left: .5vw;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
     
  }

  .playPicRecommended {
    width: 5vw;
    height: 5vw;
    position: relative;
    background-color: white;
    border-radius: 1vw;
    box-shadow: 0em .2em 0.35em 0.05vw #b7b7b7;
    border: .1vw solid #DEDEDE;
    overflow: hidden;
    margin-bottom: 1vw;
  }

  .playPicInnerRecommended {
    width: 100%;
  }

  .playTitleRecommended {
    font-size: 1.1vw;
  }