.innerSPA2 {
    width: 50vw;
    height: 90%;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    
  }

.RLSFlexCapD {
    display: flex;
    width: 98%;
    height: 12vw;
    position: relative;
    justify-content: space-between;
    top: .5vw;
  }


  .RLSitemCapD {
    height: 8vw;
    width:28%;
    text-align: center;
}

.RLSitemD {
    height: 8vw;
    width:100%;
    border-radius: 2vw;
    box-shadow: .05em 0.1em .4em 0.1vw #a8a8a8;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: .5vw;
}

.RLStextD {
    font-size: .9vw;
    color: black;
    font-weight: 550;
    padding-top: 1.1vw;
}