.newFile {
    width: 27vw;
    box-shadow: .05em 0.1em .4em 0.1vw #5f5f5f;
    border-radius:1.7vw;
  
    height: 65vh;
  }

.inputNewFile {
    margin-top: 1vw;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: .1vw solid black;
    width: 83%;
    font-weight: 500;
    font-size: 1.1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    font-family: "Montserrat", sans-serif;
    border-radius: 0vw;
    position: relative;
    left:1.5vw;
    height: 2vw;
  }


  .inputUploadNewFile {
    border-bottom: .1vw solid black;
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 83%;
    font-weight: 500;
    font-size:1.1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    position: relative;
    left:1.5vw;
    height: 2vw;
  
  }

  .newFilePersonnel {
    display: flex;
  flex-wrap: wrap;
  flex-direction: row; /* Default, for left-to-right flow */
  justify-content: flex-start; /* Align items starting from the left */
  gap: 1vw 1vw; /* Space between items, adjust as needed */
  overflow: auto;
  width: 88%;
  height: fit-content;
  max-height: 10vw;
  position: relative;
  left: 6%;
  top: 1vw;
  max-height: 10vw;

  }

  .groupNewFile{
    margin: 0%;
    padding: 0%;
    border: 0%;
    position: relative;
    width: fit-content;
    height: 1vw;
    border: 1.5px solid #707070;
    text-align: center;
    border-radius: .8vw;
    margin-right: 0vw;
    padding-left: .6vw;
    padding-right: .6vw;
    padding-top: .5vw;
    padding-bottom: .5vw;
    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    border-color: #707070;
    display: flex;
    justify-content: center;
  
    align-items: center;
  
  }


  .groupTextNewFile {
    font-size: .7vw;
    font-weight: 600;
    position: relative;
    top: 0vw;
  
    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    color: #000000;
  }

  .newFHead {
    font-size: 2.2vw;
    position: relative;
    top: 1.8vw;
    left: 1.7vw;
    font-weight: 600;
    font-style: italic;
  }
  .newFSub {
    font-size: .9vw;
    position: relative;
    top: 1.8vw;
    left: 1.7vw;
    font-weight: 500;
  }
  
  .personnelTextD {
    font-size: 1.2vw;
    font-weight: 500;
    margin-top: 3vw;
    position: relative;
    left: 1.7vw;
  }
  .personnelTextD2 {
    font-size: 1.2vw;
    font-weight: 500;
    margin-top: 2vw;
    position: relative;
  }
  
  .ATPflex {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .innerATP {
    
    width: fit-content;
    height: 3vw;
    border-radius: 1vw;
    background-color: #0072E2;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 1vw;
    text-align: center;
  
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    
  }

  .outerATP {
    position: absolute;
    width: 27vw;
    bottom: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .arrowCircle {
    width: 5vw;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: .5vw;
  
  }
  
  .outerCirc {
    width: 2vw;
    height: 2vw;
    background-color: #0072E2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border-radius: 1vw;
  font-size: 1vw;
  font-weight: 500;
  margin-right: 1.5vw;
  }
  
  .arrowUpImg{
    position: relative;
    right: 0;
  }
  
  .overallToggleFlex {
    display: flex;
    width: 73%;
    align-items: center;
    justify-content: space-between;
    position: relative;
  
    top: 1.5vw;
  
  }
  
  .starToggleTxt {
    font-size: 1.1vw;
    font-weight: 500;
    position: relative;
    left:1.7vw;
  
  }
  
  .outOval {
    height: 1.7vw;
    width: 3.5vw;
    background-color: #a5a5a5;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    transition: .5s;
    }
    
    .inOval {
      height: 1.3vw;
    width: 1.7vw;
    background-color: #ffffff;
    border-radius: 1vw;
    position: relative;
    margin-left:.2vw;
    transition: .5s;
    
    }
    
    .outOval2 {
      height: 1.7vw;
      width: 3.5vw;
      background-color: #0072E2;
      border-radius: 1vw;
      display: flex;
      align-items: center;
      transition: .5s;
      }
    
    .inOval2 {
      height: 1.3vw;
      width: 1.7vw;
      background-color: white;
      border-radius: 1vw;
      position: relative;
      margin-left:1.6vw;
      transition: .5s;
    
    }
  
    #in2 {
      margin-top: 3.5vw;
    }