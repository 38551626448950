.fullResult2 {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0072E2;
    z-index: 1103;
    width: 100vw;
    height: 100vh;
  }
  
  .Result-Cap2{
    width: 90vw;
    height: fit-content;
    margin: 0%;
    padding: 0%;
    padding-left: 5vw;
    border: 0%;
    position: fixed;
    z-index: 1101;
    left: 0;
    top: 7vw;
  }
  
  .resultTitle2{
    font-size: 7.8vw;
    font-weight: 700;
    width: 60vw;
    position: relative;
    top: 30vw;
    color: white;
  }

  .rosMidCap {
    width: 100vw;
    height: 200vw;
    background-color: white;
    position: fixed;
    top: 52vw;
    left: 0vw;
    border-radius: 7vw;
  }


  .navHead2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    position: relative;
    top: 8vw;
    font-weight: 600;
    font-size: 4.8vw;
    left: 9vw;
  }
  
  
  
  .navItem11 {
    padding-bottom: 1.5vw;
  }
  
  .navItem22 {
    padding-bottom: 1.5vw;
  }
  
  .navItem33 {
    padding-bottom: 1.5vw;
  }

  .qFlex22{
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15vw;
    margin-left: 7.5vw;
}

  .newPlayer {
    

    font-size: 4.2vw;
    font-weight: 600;

}
.tta {
    font-size: 3vw;
    font-weight: 400;

}
  