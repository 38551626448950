.PlayNotification {
    margin-top: 1.5vh;
    display:flex;
    width: 42vh;
    align-items: center;
    position: relative;
    border-radius: 1.5vh;
    background-color: white;
    box-shadow: .05em 0.1em .2em 0.08vh #cecece;
    height: 4.5vh;
    left: 1.5vh;
}

.PlayNotificationCoachPic {
        width: 3vh;
        height: 3vh;
        box-shadow: -0.05em 0.1em 0.3em 0vw #000000;
        border-radius: 1.5vh;
        overflow: hidden;
        position: relative;
        left: 1.5vh;
}

.PlayNotificationInfo {
    font-size: 1.3vh;
    font-weight: 600;
    margin-left: 3vh;
}

.PlayNotificationDuration {
    position: absolute;
    font-size: 1vh;
    font-weight: 500;
    margin-left: 10vh;
    right: 1.5vh;
    color: #0072e2;
}