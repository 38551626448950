.pFilterD {
    width: 20vw;
    height: 30vh;

    background-color: white;
    margin: auto;
    padding: 0 .7rem;
    border-radius: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 13011301130113011301130113011301;
}

.pfCapD {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 90%;
    height: 20vh;
    justify-content: space-between;

}

.pfHeadD {
    margin-top: 12%;
    font-size: 2vw;
    font-weight: 700;

}

.pfSubD {
    font-size: 1.1vw;
    font-weight: 500;
    position: relative;
    top: 1.5vw;
    
}

.groupsD {
    width: 100%;
    height: 80%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    top:0vw;
}

.groupD{
    margin: 0%;
    padding: 0%;
    border: 0%;
    position: relative;
    width: fit-content;
    height: 1.5vw;
    border: 1.5px solid #707070;
    text-align: center;
    border-radius: .7vw;
    margin-right: 1vw;
    margin-top: .5vw;
    padding-left: .6vw;
    padding-right: .6vw;
    padding-top: .2vw;
    padding-bottom: .2vw;
    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    border-color: #707070;
    display: flex;
    justify-content: center;

    align-items: center;
}


.groupTextD {
    font-size: .5vw;
    font-weight: 600;
    position: relative;
    top: 0vw;

    transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    color: #000000;
}


.flexButtonD {
    position: relative;
    bottom: 2vw;

}

.goButD {
    color: #5259FB;
    background-color: transparent;
    border: none;
    font-weight: 800;
    font-size: 1.5vw;
}