.fullStart {
  position: fixed;
  left: 0vw;
  top: 0vw;
  background-color: white;
  width: 100vw;
    height: 100vh;
    z-index: 1000000;
    
}

.start-Cap {
    width: 90vw;
    height: 100vh;
    margin: 0%;
    padding: 0%;
    padding-left: 5vw;
    border: 0%;
    position: fixed;
    z-index: 1101;
    left: 0;
    top: 0;
    background-color: white;
  }
  
  .back-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 14vw;
    top: 9vw;
  }
  
  .backTxt {
    font-weight: 600;
    font-size: 4vw;
    position: relative;
  }


  .QuizTitle {
    font-size: 7vw;
    margin-top: 19vw;
    font-weight: 800;
    width: 60vw;
  }

.QuizSub {
  font-size: 4vw;
  margin-top: 1vw;
  font-weight:500;
  width: 40vw;

}
.QuizGroup {
  font-size: 3.5vw;
  margin-top: 1vw;
  font-weight:300;
  
}
.questions {
  font-size: 3vw;
  margin-top: 7vw;
  font-weight:600;
  color: #707070;
}
.disclaimer{ 
  font-size: 3vw;
  margin-top: 1vw;
  font-weight:600;
  color: #707070;
}

.circleCap {
  position: absolute;
  top: 20vw;
  right: 3vw;
  width: 25vw;
  height: 25vw;
}
.blueCircle {
  position: absolute;
}
.quizPTS {
    position: absolute;
    top: 8.2vw;
    left: 9vw;
    font-weight: 600;
    font-size: 4vw;
}
.PTS {
  position: absolute;
  top: 12.2vw;
  font-size: 4vw;
  left:9vw;
  font-weight: 600;
}


.previewCap {
width: 100%;
height: 103vw;
border-radius: 5vw;
background-color: white;
box-shadow: 0em 0.15em 0.3em 0.35vw #c2c2c2;
position: relative;
top: 7vw;
}

.coachName {
  position: relative;
  font-weight: 600;
  position: absolute;
  font-size: 4.5vw;
  top: 8vw;
  left: 21vw;
}

.coachPrevPic {
  width: 9vw;
  height: 9vw;
  box-shadow: -0.05em 0.1em 0.3em 0vw #000000;
  border-radius: 9vw;
  overflow: hidden;
  position: relative;
  left: 8vw;
  top: 6vw;
}

.timeLeftPrev {
  position: absolute;
  right: 8vw;
  top: 9.75vw;
  font-size: 2.5vw;
  font-weight: 500;
  color: red;
}



.QP {
  width: 50%;
  position: relative;
  top: 12vw;
  left: 25%;
  box-shadow: -0.05em 0.1em 0.3em 0vw #000000;
}

.startButton {
  position: absolute;
  bottom: 6.5vw;
  width:60%;
  height:10vw;
  left: 20%;
  font-size: 4vw;
  font-weight: 600;
  border: none;
  font-family: "Montserrat", sans-serif;
  background-color: #0072E2;
  color: white;
  border-radius: 3vw;
  box-shadow: -0.05em 0.1em 0.3em 0vw #000000;


}