.profList {
    position: relative;
    top: 21vw;
    left: 7.5vw;
  }
  .profList2 {
    position: relative;
    top: 4vw;
    left: 8vw;
  }
  .profList3 {
    position: relative;
    top: 12vw;
    left: 9vw;
  }

  .hollowDot {
    width: 4vw;
    height: 4vw;
    border: .6vw solid black ;
    border-radius: 4vw;
  }

  .div-item{
    height: 15vw;
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .div-item2{
    height: 15vw;
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vw;
  }
  
  .profPic22 {
    width: 10vw;
    height: 10vw;
    position: relative;
    box-shadow: -0.09em 0.01em 0.5em 0.3vw #969696;
    border-radius: 10vw;
    overflow: hidden;
  }
  
  .pic22 {
    width: 100%;
  }

  .name2 {
    position: absolute;
    display: flex;
    width: 60vw;
    left: 15vw;
    justify-content: space-between;
  }
  
  .name3 {
    position: absolute;
    display: flex;
    width: 60vw;
    left: 12vw;
    justify-content: space-between;
    align-items: center;
  }
  
  .namehead2 {
    font-size: 4.1vw;
    font-weight: 800;
  }
  .namesub2 {
    width: 100%;
    font-size: 2.8vw;
    font-weight: 500;
    position: relative;
    top: 0.5vw;
    left: 0.2vw;
  }
  
  .lTime {
    position: absolute;
    right: 0vw;
    font-size: 3.5vw;
    font-weight: 600;
  }