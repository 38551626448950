.NFbox {
    width: 30%;
    height: 14.5vw;
    background-color: white;
    position: fixed;
    left: 35vw;
    top: 30vh;
    z-index: 100000000000000000;
    border-radius: 2vw;
    box-shadow: .02em 0.05em .6em 0.05vw #aeaeae;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 2vw;
    align-items: center;

}

.NFin {
    width: 26vw;
    height: 2.8vw;
    border: none;
    background-color: white;
    border-radius: 1vw;
    padding-left: 2vw;
    color: #bcbcbc;
    font-weight: 100;
    font-size: 1.1vw;
    position: relative;
    box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
    
  }

  .NFhead {
    margin-bottom: 2.0vw;
    font-size: 2.5vw;
    font-weight: 600;
  }

  .NFadd {
    width: fit-content;
    height: 3.5vw;
    background-color: #0072E2;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    border-radius: 1.5vw;
    font-weight: 600;
    position: relative;
    top: 2vw;
    left: .5vw;

    padding-left: 2vw;
    padding-right: 2vw;
    box-shadow: 0em 0.05em 0.3em 0.03vw rgb(62, 62, 62);
  }