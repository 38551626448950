.overallSPAH {
    width: 88vw;
    height: 100vh;
    position: relative;
    left: 12vw;
    display: flex;
    align-items: center;
  }

  .innerSPA {
    width: 34.5vw;
    height: 90%;
    border-right: 0.15vw solid rgba(195, 195, 195, 0.4);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }