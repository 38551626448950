.fullRoster {
  position: relative;
    z-index: 1103;
    width: 100%;
    height: 100%;
    top: 1.6vw;
    display: flex;
    justify-content: center;
  }
  
  .Roster-Cap{
    width: 100%;
    height: 20vw;
    margin: 0%;
    padding: 0%;
    border: 0%;
    z-index: 1101;
    position: relative;
  }
  




  .navHeadRoster{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    position: relative;
    font-weight: 600;
    font-size: 1.9vw;
    color: black;


  }
  
  
  
  .navItem1Roster {
    padding-bottom: .5vw;
  }

    
  .navItem2Roster {
    padding-bottom: .5vw;
  }

    
  .navItem3Roster {
    padding-bottom: .5vw;
  }

  .newProfileButton{
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 1.5vw;
    margin-bottom: -.5vw;

}

  .newProfileMainText {
    font-size: 1.5vw;
    font-weight: 600;
}


.tapToAdd {
    font-size: 1.1vw;
    font-weight: 400;

}
  


