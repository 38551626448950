.UPbox {
    width: 30%;
    height: 20vw;
    background-color: white;
    position: fixed;
    left: 35vw;
    top: 30vh;
    z-index: 100000000000000000;
    border-radius: 2vw;
    box-shadow: .02em 0.05em .6em 0.05vw #aeaeae;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    align-items: center;

}

.topUpBar {
    width: 92%;
    border-bottom: 0.18vw solid black;
    font-size: 1.7vw;
    background-color: transparent;
    font-weight: 600;
    z-index: 10000000000000000000000;
    height: 3vw;
    padding-bottom: .5vw;
    padding-right: .2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

  .topUPflex {
    width: 49%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .addI {
    position: relative;
    outline:none;
  }

  .addBox {
    height: 13vw;
    width: 7vw;
    border: 0.19vw solid black;
    border-radius: 1vw;
    position: relative;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: .02em 0.05em .6em 0.05vw #aeaeae;
    margin-top: 1vw;

  }

  .imgSelect {
    display: none;
}

.playPic2D {
    width: 100%;
    display: block;
    max-height: 100%;
    border-radius: 1vw;
  }