.fullProf {
    position: fixed;
    left: 0vw;
    top: 0vw;
    background-color: white;
    width: 100vw;
      height: 100vh;
      z-index: 1000000;
      
  }

  .profCap {
    width: 85%;
    position: relative;
    left: 7.5vw;
    height: 10vw;
    top: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-containerProf {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 14vw;
    top: 9vw;
    left: 7.5vw;

  }
  
  .backTxtProf {
    font-weight: 600;
    font-size: 4vw;
    position: relative;
  }

  .mainComponent2 {
    height: 40vw;
    width: 85vw;
    background-color: white;
    border-radius: 3vw;    
    box-shadow: .05em 0.1em .4em 0.1vw #5f5f5f;
    position: relative;
    top: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nameProf2{
    font-size: 6vw;
    font-weight: 700;
    margin-top: 17vw;
  }

  .groupProf{
    font-size: 4vw;
    font-weight: 400;
    margin-top: 1vw;
  }

  .flexRect {
    margin-top: 5vw;
    width: 90%;
    height:20vw;
    display: flex;
    justify-content: space-between;
  }


  .innerSquare {
    width: 22vw;
    height:100%;
    box-shadow: .05em 0.1em .4em 0.1vw #5f5f5f;
    border-radius: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ISheader {
    position: relative;
    margin-top: 3vw;
    padding-bottom: 1vw;
    font-size: 3vw;
    font-weight: 500;
    text-align: center;
  }

  .ISscore {
    position: relative;
    font-size: 7vw;
    font-weight: 600;
    
  }

  .profPicProf2 {
    position: absolute;
    width: 35vw;
    height: 35vw;
    box-shadow: -0.09em 0.01em 0.5em 0.3vw #484848;
    border-radius: 35vw;
    overflow: hidden;
    bottom: 30vw;
  }

  .picProf {
    width: 100%;
  }

  .LO {
    font-size: 4.5vw;
    font-weight: 600;
    color: white;
    background-color: #FB5252;
    width: 70vw;
    height:12vw;
    border-radius: 3vw;
    position: fixed;
    bottom: 10vw;
    display: flex;
    justify-content: center;
align-items: center;
}