.innercreate1 {
  width: 33%;
  height: 75%;
  border-right: 0.15vw solid rgba(195, 195, 195, 0.4);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
}

.questionList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 1vw;
  padding-bottom: 5vw;
  overflow-y:auto;
  align-items: center;
  overflow-x: hidden;
}

.questionListHeaderFlex{
  width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .5vw;
}

.questionListHeader {
  font-size: 2vw;
  font-weight: 700;
}

.deleteButton {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  gap: .5vw;
  color: white;
  background-color: red;
  border-radius: .5vw;
  padding: .5vw;
  border: none;
  font-weight: 600;
}

.editRemove {
  font-size: 1vw;
  font-weight: 400;
  color: red;
}
