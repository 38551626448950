.GroupSub{
    padding: 0%;
    border: 0%;
    
    font-size: 5vw;
    font-weight: 600;
    position: relative;
    padding-bottom: 1vw;
    border-bottom: .6vw solid black ;
}

.GroupSubFlex{
    position: relative;
    margin-top: 5vw;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.GroupSubFlex2{
    position: relative;
    margin-top: 10vw;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.groupHead {
    margin: 0%;
  padding: 0%;
  border: 0%;
    width: 101%;
  font-size: 8vw;
  font-style: italic;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }

  .addClass{
    outline: none;
  }
  